.firebaseui-container {
  border-radius: 20px;
  background-color: #b5c1d6;
}

.firebaseui-id-submit,
.firebaseui-button,
.mdl-button,
.mdl-js-button,
.mdl-button--raised,
.mdl-button--colored {
  background: #282c34;
}