.pro-sidebar,
.sidebar {
  z-index: 49 !important;
}

.pro-sidebar {
  float: left;
  position: relative;
  z-index: 5;
}
.pro-menu-item {
  margin: 8px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 15px;
}
.pro-inner-item {
  padding-top: 9px;
  padding-bottom: 9px;
}
.pro-menu-item:hover {
  background: #3d3d3d !important;
  color: #ffffff !important;
}
.pro-inner-item:hover {
  color: #ffffff !important;
}

.item-selected {
  background: #000000 !important;
  color: #ffffff !important;
}
.react-slidedown,
.pro-inner-list-item {
  color: #000000 !important;
  background: #ffffff !important;
}
.pro-inner-item:before {
  display: none !important;
}

.pro-inner-item:hover .pro-icon-wrapper .pro-icon {
  animation: none !important;
}
.pro-icon {
  background: #ffffff;
  color: #000000;
  font-size: 1.5rem;
  line-height: 2rem;
}

.pro-sub-menu:hover {
  color: #000000 !important;
  background: #ffffff !important;
}
.pro-sub-menu > .pro-inner-item:hover {
  color: #3d3d3d !important;
}
.pro-sub-menu > .pro-inner-item > {
  color: #000000 !important;
  background: #ffffff !important;
}
.open {
  color: #3d3d3d !important;
}

.pro-sidebar,
.pro-sidebar-inner {
  min-height: 100vh;
  font-weight: bold;
  color: #000000 !important;
  background: #ffffff !important;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
