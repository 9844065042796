.user-card {
  padding: 20px;
}

@media (min-width: 1000px) {
  .user-card {
    width: "50%";
    min-width: "50%";
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
  .user-card {
    width: "30%";
    min-width: "30%";
  }
}

.inactive {
  background-color: rgba(255, 255, 255, 0.4);
}
